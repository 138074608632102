/**
 * @generated SignedSource<<e0f6e2a82267d1b26dce2cacca008e02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoteDetailsFragment$data = {
  readonly proposal: {
    readonly number: string;
    readonly title: string;
    readonly totalValue: string;
  };
  readonly reason: string;
  readonly supportDetailed: number;
  readonly transaction: {
    readonly block: {
      readonly timestamp: number;
    };
  };
  readonly votes: {
    readonly amount: {
      readonly amount: string;
      readonly decimals: number;
    };
  };
  readonly " $fragmentType": "VoteDetailsFragment";
};
export type VoteDetailsFragment$key = {
  readonly " $data"?: VoteDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoteDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoteDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportDetailed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "votes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TokenAmount",
          "kind": "LinkedField",
          "name": "amount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "decimals",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Transaction",
      "kind": "LinkedField",
      "name": "transaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Block",
          "kind": "LinkedField",
          "name": "block",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OnChainProposal",
      "kind": "LinkedField",
      "name": "proposal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Vote",
  "abstractKey": null
};

(node as any).hash = "bdd164813c4942d12693f1f96f8e1911";

export default node;
