import { css } from "@emotion/css";
import * as theme from "../../theme";
import { useCallback, useMemo, useRef, useState } from "react";
import { usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { PastProposalsFormSectionProposalListFragment$key } from "./__generated__/PastProposalsFormSectionProposalListFragment.graphql";
import {
  formSectionContainerStyles,
  sharedInputStyle,
} from "./TopIssuesFormSection";
import useClickOutside from "@restart/ui/useClickOutside";
import { CloseButton } from "./CloseButton";
import { Form } from "./DelegateStatementForm";
import { HStack, VStack } from "../../components/VStack";
import { shortenId } from "../DelegatePage/VoteDetails";
import InfiniteScroll from "react-infinite-scroller";

type Props = {
  queryFragment: PastProposalsFormSectionProposalListFragment$key;
  form: Form;
};

export const formSectionHeadingStyle = css`
  font-weight: bold;
`;

export function PastProposalsFormSection({ queryFragment, form }: Props) {
  return (
    <div className={formSectionContainerStyles}>
      <VStack>
        <h3 className={formSectionHeadingStyle}>Views on past proposals</h3>

        <VStack
          gap="4"
          className={css`
            margin-top: ${theme.spacing["4"]};
          `}
        >
          <ProposalList
            selectedProposals={form.state.mostValuableProposals}
            setSelectedProposals={form.onChange.mostValuableProposals}
            title="Most valuable"
            queryFragment={queryFragment}
          />

          <ProposalList
            selectedProposals={form.state.leastValuableProposals}
            setSelectedProposals={form.onChange.leastValuableProposals}
            title="Least valuable"
            queryFragment={queryFragment}
          />
        </VStack>
      </VStack>
    </div>
  );
}

type ProposalListProps = {
  title: string;
  queryFragment: PastProposalsFormSectionProposalListFragment$key;
  setSelectedProposals: (
    updater: (oldProposals: SelectedProposal[]) => SelectedProposal[]
  ) => void;
  selectedProposals: SelectedProposal[];
};

function ProposalList({
  title,
  queryFragment,
  selectedProposals,
  setSelectedProposals,
}: ProposalListProps) {
  const {
    data: { allProposals },
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    graphql`
      fragment PastProposalsFormSectionProposalListFragment on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
      )
      @refetchable(queryName: "EditDelegatePageRouteProposalsPaginationQuery") {
        allProposals: proposals(after: $after, first: $first)
          @connection(key: "PastProposalsForm_allProposals") {
          edges {
            node {
              __typename
              ... on OnChainProposalType {
                id
                onChainProposal {
                  title
                  number
                }
              }

              ... on SnapshotProposalType {
                id
                snapshotProposal {
                  title
                }
              }
            }
          }
        }
      }
    `,
    queryFragment as PastProposalsFormSectionProposalListFragment$key
  );

  const loadMore = useCallback(() => {
    loadNext(30);
  }, [loadNext]);

  const [filterText, setFilterText] = useState("");

  // @ts-ignore
  const mappedProposals: SearchableProposal[] = useMemo(
    () =>
      allProposals.edges.map((edge) => {
        switch (edge.node.__typename) {
          case "OnChainProposalType":
            return {
              id: edge.node.id,
              type: "On-chain",
              title: edge.node.onChainProposal.title,
              searchValue: `on-chain#${shortenId(
                edge.node.onChainProposal.number
              )} ${edge.node.onChainProposal.title ?? ""}`.toLowerCase(),
            };

          case "SnapshotProposalType":
            return {
              id: edge.node.id,
              type: "Snapshot",
              title: edge.node.snapshotProposal.title,
              searchValue: `snapshot#${edge.node.snapshotProposal.title.toLowerCase()}`,
            };

          default:
            throw new Error(`unknown proposal type`);
        }
      }),
    [allProposals]
  );

  const firstStageFilteredProposals = useMemo(
    () => proposalsMatchingText(mappedProposals, filterText),
    [mappedProposals, filterText]
  );

  const filteredProposals = useMemo(
    () =>
      proposalsNotIncludingSelected(
        firstStageFilteredProposals,
        selectedProposals
      ),
    [firstStageFilteredProposals, selectedProposals]
  );

  const removeSelectedProposal = useCallback(
    function removeSelectedProposal(id: string) {
      setSelectedProposals((oldProposals) =>
        oldProposals.filter((proposal) => proposal.id !== id)
      );
    },
    [setSelectedProposals]
  );

  const selectedProposalsWithSearchableProposal = useMemo(
    () =>
      selectedProposals.flatMap((selectedProposal) => {
        const proposal = mappedProposals.find(
          (needle) => needle.id === selectedProposal.id
        );
        if (!proposal) {
          return [];
        }

        return proposal;
      }),
    [selectedProposals, mappedProposals]
  );

  const [isFocused, setIsFocused] = useState(false);

  const setFocused = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const setBlurred = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const handleSuggestedProposalClicked = useCallback(
    function handleSuggestedProposalClicked(proposal: SearchableProposal) {
      setSelectedProposals((last) => [...last, { id: proposal.id }]);
      setFilterText("");
      setBlurred();
    },
    [setSelectedProposals, setFilterText, setBlurred]
  );

  const focusContainerRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(focusContainerRef, setBlurred);

  return (
    <VStack
      gap="1"
      className={css`
        flex: 1;
      `}
    >
      <h3
        className={css`
          font-size: ${theme.fontSize.sm};
          font-weight: bold;
        `}
      >
        {title}
      </h3>

      {selectedProposalsWithSearchableProposal.map((proposal) => (
        <ProposalCard
          proposal={proposal}
          onClose={() => removeSelectedProposal(proposal.id)}
        />
      ))}

      <div
        ref={focusContainerRef}
        className={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing["2"]};

          position: relative;
        `}
      >
        <input
          className={css`
            ${sharedInputStyle}
          `}
          placeholder="Start typing to search proposals "
          type="text"
          value={filterText}
          onFocus={setFocused}
          onChange={(e) => setFilterText(e.target.value)}
        />

        <VStack
          className={css`
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            border-color: ${theme.colors.gray["300"]};
            box-shadow: ${theme.boxShadow.newDefault};
            z-index: 3;
          `}
        >
          <InfiniteScroll loadMore={loadMore} hasMore={hasNext}>
            {isFocused &&
              filteredProposals.map((proposal) => (
                <ProposalCard
                  key={proposal.id}
                  proposal={proposal}
                  onClick={() => handleSuggestedProposalClicked(proposal)}
                />
              ))}
          </InfiniteScroll>

          {isLoadingNext && isFocused && (
            <HStack
              justifyContent="center"
              className={css`
                padding: ${theme.spacing["8"]};
              `}
            >
              Loading...
            </HStack>
          )}
        </VStack>
      </div>
    </VStack>
  );
}

export type SelectedProposal = {
  id: string;
};

type SearchableProposal = {
  id: string;
  type: string;
  title: string | null;
  searchValue: string;
};

function proposalsNotIncludingSelected(
  proposals: SearchableProposal[],
  selected: SelectedProposal[]
): SearchableProposal[] {
  return proposals.filter(
    (searchableProposal) =>
      !selected.find(
        (selectedProposal) => selectedProposal.id === searchableProposal.id
      )
  );
}

function proposalsMatchingText(
  proposals: SearchableProposal[],
  filterText: string
): SearchableProposal[] {
  return proposals.filter((proposal) =>
    proposal.searchValue.includes(filterText.toLowerCase())
  );
}

type ProposalCardProps = {
  proposal: SearchableProposal;
  onClick?: () => void;
  onClose?: () => void;
};

function ProposalCard({ proposal, onClick, onClose }: ProposalCardProps) {
  return (
    <div
      onClick={onClick}
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: ${theme.colors.white};

        ${onClick &&
        css`
          cursor: pointer;

          :hover {
            background: ${theme.colors.gray["200"]};
          }
        `}
      `}
    >
      <div
        className={css`
          padding: ${theme.spacing["3"]} ${theme.spacing["3"]};
        `}
      >
        {proposal.type} - {proposal.title}
      </div>

      {onClose && <CloseButton onClick={onClose} />}
    </div>
  );
}
