/**
 * @generated SignedSource<<099e0b79f2894e62355876ac085a2dde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageHeaderQuery$variables = {
  address: string;
  skip: boolean;
};
export type PageHeaderQuery$data = {
  readonly delegate?: {
    readonly statement: {
      readonly __typename: "DelegateStatement";
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileDropDownButtonFragment">;
  };
};
export type PageHeaderQuery = {
  response: PageHeaderQuery$data;
  variables: PageHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "addressOrEnsName",
    "variableName": "address"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DelegateStatement",
  "kind": "LinkedField",
  "name": "statement",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResolvedName",
      "kind": "LinkedField",
      "name": "resolvedName",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TokenAmount",
    "kind": "LinkedField",
    "name": "amount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "decimals",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageHeaderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Delegate",
            "kind": "LinkedField",
            "name": "delegate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileDropDownButtonFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageHeaderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Delegate",
            "kind": "LinkedField",
            "name": "delegate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Delegate",
                "kind": "LinkedField",
                "name": "delegatingTo",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VotingPower",
                "kind": "LinkedField",
                "name": "amountOwned",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VotingPower",
                "kind": "LinkedField",
                "name": "tokensRepresented",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DelegateMetrics",
                "kind": "LinkedField",
                "name": "delegateMetrics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tokenHoldersRepresentedCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "79310dbeb4161eb110430467da5b7750",
    "id": null,
    "metadata": {},
    "name": "PageHeaderQuery",
    "operationKind": "query",
    "text": "query PageHeaderQuery(\n  $address: String!\n  $skip: Boolean!\n) {\n  delegate(addressOrEnsName: $address) @skip(if: $skip) {\n    statement {\n      __typename\n    }\n    ...ProfileDropDownButtonFragment\n    id\n  }\n}\n\nfragment ENSAvatarFragment on ResolvedName {\n  name\n}\n\nfragment NounResolvedNameFragment on ResolvedName {\n  address\n  name\n}\n\nfragment ProfileDropDownButtonFragment on Delegate {\n  address {\n    resolvedName {\n      address\n      name\n      ...NounResolvedNameFragment\n      ...ENSAvatarFragment\n    }\n  }\n  delegatingTo {\n    address {\n      resolvedName {\n        address\n        name\n        ...NounResolvedNameFragment\n        ...ENSAvatarFragment\n      }\n    }\n    id\n  }\n  amountOwned {\n    amount {\n      ...TokenAmountAsCurrencyDisplayFragment\n    }\n  }\n  tokensRepresented {\n    amount {\n      ...TokenAmountAsCurrencyDisplayFragment\n    }\n  }\n  delegateMetrics {\n    tokenHoldersRepresentedCount\n  }\n}\n\nfragment TokenAmountAsCurrencyDisplayFragment on TokenAmount {\n  amount\n  decimals\n  currency\n}\n"
  }
};
})();

(node as any).hash = "288ac17b8a2bf52fa23e321e2893c909";

export default node;
