/**
 * @generated SignedSource<<b98b021388629f62ae080a29629296df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DelegatesOrder = "mostDelegates" | "mostVotes" | "mostVotesMostPower" | "mostVotingPower" | "%future added value";
export type DelegatesWhere = "withStatement" | "withoutStatement" | "%future added value";
export type DelegatesContainerPaginationQuery$variables = {
  after?: string | null;
  filterBy?: DelegatesWhere | null;
  first?: number | null;
  orderBy?: DelegatesOrder | null;
};
export type DelegatesContainerPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DelegatesContainerFragment">;
};
export type DelegatesContainerPaginationQuery = {
  response: DelegatesContainerPaginationQuery$data;
  variables: DelegatesContainerPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  },
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": "mostVotesMostPower",
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "filterBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DelegatesContainerPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "filterBy",
            "variableName": "filterBy"
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "DelegatesContainerFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DelegatesContainerPaginationQuery",
    "selections": [
      {
        "alias": "voters",
        "args": (v4/*: any*/),
        "concreteType": "DelegatesConnection",
        "kind": "LinkedField",
        "name": "delegates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DelegatesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Delegate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResolvedName",
                        "kind": "LinkedField",
                        "name": "resolvedName",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DelegateStatement",
                    "kind": "LinkedField",
                    "name": "statement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "summary",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "twitter",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discord",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VotingPower",
                    "kind": "LinkedField",
                    "name": "tokensRepresented",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TokenAmount",
                        "kind": "LinkedField",
                        "name": "amount",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "decimals",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "voters",
        "args": (v4/*: any*/),
        "filters": [
          "orderBy",
          "where"
        ],
        "handle": "connection",
        "key": "DelegatesContainerFragment_voters",
        "kind": "LinkedHandle",
        "name": "delegates"
      }
    ]
  },
  "params": {
    "cacheID": "6555c109f893261a6c13c9f9da07b88e",
    "id": null,
    "metadata": {},
    "name": "DelegatesContainerPaginationQuery",
    "operationKind": "query",
    "text": "query DelegatesContainerPaginationQuery(\n  $after: String\n  $filterBy: DelegatesWhere\n  $first: Int = 30\n  $orderBy: DelegatesOrder = mostVotesMostPower\n) {\n  ...DelegatesContainerFragment_3Jfei\n}\n\nfragment DelegateProfileImageFragment on Delegate {\n  address {\n    resolvedName {\n      address\n      ...NounResolvedNameFragment\n      ...ENSAvatarFragment\n    }\n  }\n  tokensRepresented {\n    amount {\n      ...TokenAmountAsCurrencyDisplayFragment\n    }\n  }\n}\n\nfragment DelegatesContainerFragment_3Jfei on Query {\n  voters: delegates(first: $first, after: $after, orderBy: $orderBy, where: $filterBy) {\n    edges {\n      node {\n        id\n        ...VoterCardFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ENSAvatarFragment on ResolvedName {\n  name\n}\n\nfragment NounResolvedNameFragment on ResolvedName {\n  address\n  name\n}\n\nfragment TokenAmountAsCurrencyDisplayFragment on TokenAmount {\n  amount\n  decimals\n  currency\n}\n\nfragment VoterCardFragment on Delegate {\n  address {\n    resolvedName {\n      address\n      name\n      ...NounResolvedNameFragment\n    }\n  }\n  statement {\n    summary\n  }\n  ...VoterPanelActionsFragment\n  ...DelegateProfileImageFragment\n}\n\nfragment VoterPanelActionsDelegateButtonFragment on Delegate {\n  address {\n    resolvedName {\n      address\n    }\n  }\n}\n\nfragment VoterPanelActionsFragment on Delegate {\n  statement {\n    twitter\n    discord\n  }\n  ...VoterPanelActionsDelegateButtonFragment\n}\n"
  }
};
})();

(node as any).hash = "3975ee3a9e78104e69b096a35ae39425";

export default node;
