/**
 * @generated SignedSource<<8eb4e0c6b5ab14be6948b7d684e596c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterCardFragment$data = {
  readonly address: {
    readonly resolvedName: {
      readonly address: string;
      readonly name: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"NounResolvedNameFragment">;
    };
  };
  readonly statement: {
    readonly summary: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DelegateProfileImageFragment" | "VoterPanelActionsFragment">;
  readonly " $fragmentType": "VoterCardFragment";
};
export type VoterCardFragment$key = {
  readonly " $data"?: VoterCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResolvedName",
          "kind": "LinkedField",
          "name": "resolvedName",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NounResolvedNameFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DelegateStatement",
      "kind": "LinkedField",
      "name": "statement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoterPanelActionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DelegateProfileImageFragment"
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};

(node as any).hash = "c3cfa7a81db985d5c27e90e41cfc1ac2";

export default node;
