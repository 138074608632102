import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import * as theme from "../../theme";
import { HStack, VStack } from "../../components/VStack";
import { UserIcon } from "@heroicons/react/20/solid";
import { css } from "@emotion/css";
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { NounResolvedLink } from "../../components/NounResolvedLink";
import { ReactNode } from "react";
import {
  colorForSupportType,
  SupportTextProps,
} from "../DelegatePage/VoteDetailsContainer";
import { CastVoteDialogQuery } from "./__generated__/CastVoteDialogQuery.graphql";
import { TokenAmountAsCurrencyDisplay } from "../../components/TokenAmountAsCurrencyDisplay";

import { useContractWrite } from "../../hooks/useContractWrite";
import { useAccount } from "wagmi";
import { ENSGovernor } from "../../contracts/generated";
import { ENSGovernorContract, NETWORK_ID } from "../../contracts/contracts";
import { icons } from "../../icons/icons";

type Props = {
  proposalId: string;
  reason: string;
  supportType: SupportTextProps["supportType"];
  onVoteSuccess: () => void;
  closeDialog: () => void;
};

// TODO: Better rendering for users with no voting power
export function CastVoteDialog(props: Props) {
  return (
    <VStack
      alignItems="center"
      className={css`
        padding: ${theme.spacing["8"]};
      `}
    >
      <Dialog.Panel
        as={motion.div}
        initial={{
          scale: 0.9,
          translateY: theme.spacing["8"],
        }}
        animate={{ translateY: 0, scale: 1 }}
        className={css`
          width: 100%;
          max-width: ${theme.maxWidth.xs};
          background: ${theme.colors.white};
          border-radius: ${theme.spacing["3"]};
          padding: ${theme.spacing["6"]};
        `}
      >
        <CastVoteDialogContents {...props} />
      </Dialog.Panel>
    </VStack>
  );
}

function CastVoteDialogContents({
  proposalId,
  reason,
  supportType,
  onVoteSuccess,
}: Props) {
  // Ideal flow (not implemented yet):
  // 1. Check that user doesn't have a delegate
  // 2. Check that user has >0 Nouns
  // 3. Check that user has not already voted
  // Notes:
  // If user has no nouns, fields are null

  const { address: accountAddress } = useAccount();

  const { delegate } = useLazyLoadQuery<CastVoteDialogQuery>(
    graphql`
      query CastVoteDialogQuery(
        $accountAddress: String!
        $skip: Boolean!
        $proposalId: String!
      ) {
        delegate(addressOrEnsName: $accountAddress) @skip(if: $skip) {
          statement {
            __typename
          }

          address {
            resolvedName {
              ...NounResolvedLinkFragment
            }
          }

          delegateSnapshot(proposalId: $proposalId) {
            tokensRepresented {
              amount {
                ...TokenAmountAsCurrencyDisplayFragment
              }
            }
          }
        }
      }
    `,
    {
      accountAddress: accountAddress ?? "",
      skip: !accountAddress,
      proposalId: proposalId,
    }
  );

  const { write, isLoading, isError, isSuccess, canExecute, txHash } =
    useContractWrite<ENSGovernor, "castVoteWithReason">(
      ENSGovernorContract,
      "castVoteWithReason",
      [proposalId, ["AGAINST", "FOR", "ABSTAIN"].indexOf(supportType), reason],
      () => {
        onVoteSuccess();
      }
    );

  if (!delegate) {
    // todo: log
    return null;
  }

  return (
    <VStack
      gap="6"
      className={css`
        font-size: ${theme.fontSize["xs"]};
      `}
    >
      <VStack gap="2">
        <HStack
          justifyContent="space-between"
          className={css`
            font-weight: ${theme.fontWeight.semibold};
            line-height: ${theme.lineHeight.none};
          `}
        >
          <HStack
            className={css`
              color: ${theme.colors.black};
            `}
          >
            {delegate.address?.resolvedName ? (
              <NounResolvedLink resolvedName={delegate.address?.resolvedName} />
            ) : (
              "anonymous"
            )}
            <div
              className={css`
                color: ${colorForSupportType(supportType)};
              `}
            >
              &nbsp;voting {supportType.toLowerCase()}
            </div>
          </HStack>
          <HStack
            className={css`
              color: #66676b;
            `}
          >
            <div>
              <TokenAmountAsCurrencyDisplay
                fragment={delegate.delegateSnapshot.tokensRepresented.amount}
              />
            </div>
            <div
              className={css`
                width: ${theme.spacing["4"]};
                height: ${theme.spacing["4"]};
              `}
            >
              <UserIcon />
            </div>
          </HStack>
        </HStack>
        <div
          className={css`
            color: ${theme.colors.gray["4f"]};
          `}
        >
          {reason ? reason : "No reason provided"}
        </div>
      </VStack>
      <VStack
        className={css`
          border-radius: ${theme.spacing["2"]};
          border: 1px solid ${theme.colors.gray.eb};
        `}
      >
        {isError ? (
          <DisplayMessage message="Transaction reverted" />
        ) : isSuccess ? (
          <DisplayMessage
            message={
              txHash
                ? messageWithLink({
                    message: successMessageText,
                    link: linkForTransaction(txHash),
                    linkText: "View on Etherscan",
                  })
                : successMessageText
            }
          />
        ) : isLoading ? (
          <DisplayMessage message="Casting vote" icon={icons.spinner} />
        ) : !canExecute ? (
          <DisplayMessage message="Voting is not possible at this time" />
        ) : (
          <VStack
            className={css`
              width: 100%;
              z-index: 1;
              position: relative;
            `}
            justifyContent="space-between"
          >
            <VoteButton onClick={write}>Vote</VoteButton>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
}

const VoteButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className={css`
        text-align: center;
        border-radius: ${theme.spacing["2"]};
        width: 100%;
        border: 1px solid ${theme.colors.gray.eb};
        font-weight: ${theme.fontWeight.semibold};
        font-size: ${theme.fontSize.xs};
        color: ${theme.colors.black};
        padding: ${theme.spacing["2"]} ${theme.spacing["6"]};
        cursor: pointer;

        ${!onClick &&
        css`
          background: ${theme.colors.gray.eb};
          color: ${theme.colors.gray["700"]};
          cursor: not-allowed;
        `}

        :hover {
          background: ${theme.colors.gray.eb};
        }
      `}
    >
      {children}
    </div>
  );
};

const successMessageText =
  " Success! Your vote has been cast. It will appear once the transaction is confirmed.";

function DisplayMessage({
  message,
  icon,
}: {
  message: string | JSX.Element;
  icon?: string;
}) {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      className={css`
        padding: ${theme.spacing["4"]};
      `}
    >
      <div
        className={css`
          font-weight: ${theme.fontWeight.medium};
        `}
      >
        {message}
      </div>
      {icon && (
        <img
          src={icon}
          alt={icon}
          className={css`
            height: 20px;
          `}
        />
      )}
    </HStack>
  );
}

export function LoadingVote() {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      className={css`
        width: 100%;
        z-index: 1;
        position: relative;
        padding: ${theme.spacing["4"]};
        border-radius: ${theme.spacing["2"]};
        border: 1px solid ${theme.colors.gray.eb};
      `}
    >
      <div
        className={css`
          font-weight: ${theme.fontWeight.medium};
        `}
      >
        Writing your vote to the chain
      </div>
      <img src={icons.spinner} alt={icons.spinner} />
    </HStack>
  );
}

function messageWithLink({
  message,
  link,
  linkText,
}: {
  message: string;
  link: string;
  linkText?: string;
}) {
  return (
    <>
      {message}{" "}
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={css`
          font-weight: ${theme.fontWeight.medium};
          color: ${theme.colors.gray["700"]};
        `}
      >
        {linkText || link}
      </a>
    </>
  );
}

function linkForTransaction(txHash: string) {
  return `https://${
    NETWORK_ID === 1 ? "" : "sepolia."
  }etherscan.io/tx/${txHash}`;
}
