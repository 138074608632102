/**
 * @generated SignedSource<<81dd2505bafd67002531e4a0b8d36f61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CastVoteDialogQuery$variables = {
  accountAddress: string;
  proposalId: string;
  skip: boolean;
};
export type CastVoteDialogQuery$data = {
  readonly delegate?: {
    readonly address: {
      readonly resolvedName: {
        readonly " $fragmentSpreads": FragmentRefs<"NounResolvedLinkFragment">;
      };
    };
    readonly delegateSnapshot: {
      readonly tokensRepresented: {
        readonly amount: {
          readonly " $fragmentSpreads": FragmentRefs<"TokenAmountAsCurrencyDisplayFragment">;
        };
      };
    };
    readonly statement: {
      readonly __typename: "DelegateStatement";
    } | null;
  };
};
export type CastVoteDialogQuery = {
  response: CastVoteDialogQuery$data;
  variables: CastVoteDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountAddress"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "proposalId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "kind": "Variable",
    "name": "addressOrEnsName",
    "variableName": "accountAddress"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "DelegateStatement",
  "kind": "LinkedField",
  "name": "statement",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "proposalId",
    "variableName": "proposalId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CastVoteDialogQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Delegate",
            "kind": "LinkedField",
            "name": "delegate",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResolvedName",
                    "kind": "LinkedField",
                    "name": "resolvedName",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NounResolvedLinkFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "DelegateSnapshot",
                "kind": "LinkedField",
                "name": "delegateSnapshot",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VotingPower",
                    "kind": "LinkedField",
                    "name": "tokensRepresented",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TokenAmount",
                        "kind": "LinkedField",
                        "name": "amount",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TokenAmountAsCurrencyDisplayFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CastVoteDialogQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Delegate",
            "kind": "LinkedField",
            "name": "delegate",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResolvedName",
                    "kind": "LinkedField",
                    "name": "resolvedName",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "DelegateSnapshot",
                "kind": "LinkedField",
                "name": "delegateSnapshot",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VotingPower",
                    "kind": "LinkedField",
                    "name": "tokensRepresented",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TokenAmount",
                        "kind": "LinkedField",
                        "name": "amount",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "decimals",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "65bb4e9c180e40aa78636d07c6dddbc9",
    "id": null,
    "metadata": {},
    "name": "CastVoteDialogQuery",
    "operationKind": "query",
    "text": "query CastVoteDialogQuery(\n  $accountAddress: String!\n  $skip: Boolean!\n  $proposalId: String!\n) {\n  delegate(addressOrEnsName: $accountAddress) @skip(if: $skip) {\n    statement {\n      __typename\n    }\n    address {\n      resolvedName {\n        ...NounResolvedLinkFragment\n      }\n    }\n    delegateSnapshot(proposalId: $proposalId) {\n      tokensRepresented {\n        amount {\n          ...TokenAmountAsCurrencyDisplayFragment\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment NounResolvedLinkFragment on ResolvedName {\n  address\n  ...NounResolvedNameFragment\n}\n\nfragment NounResolvedNameFragment on ResolvedName {\n  address\n  name\n}\n\nfragment TokenAmountAsCurrencyDisplayFragment on TokenAmount {\n  amount\n  decimals\n  currency\n}\n"
  }
};
})();

(node as any).hash = "215f47ff6b6ff73fb7e30ea5a9571878";

export default node;
