import { ethers } from "ethers";
import { makeContractInstance } from "../hooks/useContractWrite";
import { ENSToken__factory } from "./generated/factories/ENSToken__factory";
import { ENSGovernor__factory } from "./generated";

export type Token = {
  name: string;
  address: string;
  decimals: number;
};

const env = process.env.REACT_APP_DEPLOY_ENV || "dev";

const governanceTokenContract = makeContractInstance({
  factory: ENSToken__factory,
  address: "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72",
  startingBlock: 13533418,
});

const governorContract = makeContractInstance({
  factory: ENSGovernor__factory,
  address: "0x323A76393544d5ecca80cd6ef2A560C6a395b7E3",
  startingBlock: 13533772,
});

const governanceTokenContractSepolia = makeContractInstance({
  factory: ENSToken__factory,
  address: "0xca83e6932cf4f03cdd6238be0ffcf2fe97854f67",
  startingBlock: 3779707,
});

const governorContractSepolia = makeContractInstance({
  factory: ENSGovernor__factory,
  address: "0x630A6A268191c654ce084aAd2D7910fF651e0797",
  startingBlock: 3779707,
});

export const ENSTokenContract =
  env === "prod" ? governanceTokenContract : governanceTokenContractSepolia;

export const ENSGovernorContract =
  env === "prod" ? governorContract : governorContractSepolia;

export const ENSTimelockContractAddress =
  env === "prod"
    ? "0xFe89cc7aBB2C4183683ab71653C4cdc9B02D44b7"
    : "0x1E9BE5E89AE5ccBf047477Ac01D3d4b0eBFB328e";

export const NETWORK_ID = env === "prod" ? 1 : 11155111;

export const tokens = [
  { name: "ETH", address: ethers.constants.AddressZero, decimals: 18 },
  {
    name: "USDC",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    decimals: 6,
  },
  {
    name: "ENS",
    address: ENSTokenContract.address,
    decimals: 18,
  },
];
