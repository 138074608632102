import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { inset0, shadow } from "../theme";
import * as theme from "../theme";
import { HStack, VStack } from "./VStack";
import { useContractWrite } from "../hooks/useContractWrite";
import { css } from "@emotion/css";
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { DelegateDialogQuery } from "./__generated__/DelegateDialogQuery.graphql";
import { NounResolvedLink } from "./NounResolvedLink";
import { ReactNode } from "react";
import { TokenAmountAsCurrencyDisplay } from "./TokenAmountAsCurrencyDisplay";
import ensIcon from "../icons/ens.png";
import { TokenAmountAsCurrencyDisplayFragment$key } from "./__generated__/TokenAmountAsCurrencyDisplayFragment.graphql";
import { ENSTokenContract } from "../contracts/contracts";
import { ENSToken } from "../contracts/generated";
import { useAccount } from "wagmi";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import { ConnectKitButton } from "connectkit";

export function DelegateDialog({
  target,
  completeDelegation,
}: {
  target: string;
  completeDelegation: () => void;
}) {
  return (
    <VStack
      alignItems="center"
      className={css`
        padding: ${theme.spacing["8"]};
        overflow-y: scroll;
      `}
    >
      <Dialog.Panel
        as={motion.div}
        initial={{
          scale: 0.9,
          translateY: theme.spacing["8"],
        }}
        animate={{ translateY: 0, scale: 1 }}
        className={css`
          width: 100%;
          max-width: ${theme.maxWidth.md};
          background: ${theme.colors.white};
          border-radius: ${theme.spacing["3"]};
          padding: ${theme.spacing["6"]};
        `}
      >
        <DelegateDialogContents
          targetAccountAddress={target}
          completeDelegation={completeDelegation}
        />
      </Dialog.Panel>
    </VStack>
  );
}

function ENSAmountDisplay({
  fragment,
}: {
  fragment: TokenAmountAsCurrencyDisplayFragment$key;
}) {
  if (!fragment) {
    return null;
  }

  return (
    <HStack
      gap="2"
      className={css`
        color: ${theme.colors.black};
        font-size: ${theme.fontSize["4xl"]};
      `}
      alignItems="center"
    >
      <img
        className={css`
          width: ${theme.spacing["8"]};
          height: ${theme.spacing["8"]};
        `}
        src={ensIcon}
        alt="ens-token"
      />

      <TokenAmountAsCurrencyDisplay fragment={fragment} />
    </HStack>
  );
}

function DelegateDialogContents({
  targetAccountAddress,
  completeDelegation,
}: {
  targetAccountAddress: string;
  completeDelegation: () => void;
}) {
  const { address: accountAddress } = useAccount();
  const { currentAccount, delegate } = useLazyLoadQuery<DelegateDialogQuery>(
    graphql`
      query DelegateDialogQuery(
        $targetAccountAddress: String!
        $currentAccountAddress: String!
        $skipCurrentAccount: Boolean!
      ) {
        delegate(addressOrEnsName: $targetAccountAddress) {
          address {
            resolvedName {
              address
              ...NounResolvedLinkFragment
            }
          }

          tokensRepresented {
            amount {
              ...TokenAmountAsCurrencyDisplayFragment
            }
          }
        }

        currentAccount: delegate(addressOrEnsName: $currentAccountAddress)
          @skip(if: $skipCurrentAccount) {
          amountOwned {
            amount {
              ...TokenAmountAsCurrencyDisplayFragment
            }
          }
        }
      }
    `,
    {
      targetAccountAddress,
      currentAccountAddress: accountAddress ?? "",
      skipCurrentAccount: !accountAddress,
    }
  );

  const {
    write: delegateVotes,
    isLoading,
    isSuccess,
    isError,
  } = useContractWrite<ENSToken, "delegate">(
    ENSTokenContract,
    "delegate",
    [delegate?.address?.resolvedName?.address as any],
    () => {}
  );

  if (!delegate) {
    return null;
  }

  return (
    <VStack gap="8" alignItems="stretch">
      <VStack
        gap="3"
        alignItems="center"
        className={css`
          padding-top: ${theme.spacing["3"]};
          padding-bottom: ${theme.spacing["3"]};
          border-radius: ${theme.spacing["2"]};
          background: rgba(250, 250, 250, 0.95);
          border: 1px solid ${theme.colors.gray.eb};

          color: #66676b;
          font-size: ${theme.fontSize.xs};
        `}
      >
        <VStack
          className={css`
            padding: ${theme.spacing["4"]} ${theme.spacing["12"]};
          `}
          alignItems="center"
          gap="3"
        >
          {(() => {
            if (!currentAccount?.amountOwned?.amount) {
              return <div>You don't have any tokens to delegate</div>;
            } else {
              return (
                <>
                  <div>Delegating your</div>

                  <ENSAmountDisplay
                    fragment={currentAccount.amountOwned.amount}
                  />
                </>
              );
            }
          })()}
        </VStack>

        <VStack
          className={css`
            width: 100%;
            z-index: 1;
            position: relative;
          `}
          alignItems="center"
        >
          <VStack
            justifyContent="center"
            className={css`
              position: absolute;
              ${inset0};
              z-index: -1;
            `}
          >
            <div
              className={css`
                height: 1px;
                background: ${theme.colors.gray.eb};
              `}
            />
          </VStack>

          <VStack
            className={css`
              width: ${theme.spacing["10"]};
              height: ${theme.spacing["10"]};
              background: ${theme.colors.white};
              border: 1px solid ${theme.colors.gray.eb};
              border-radius: ${theme.borderRadius.full};
              padding: ${theme.spacing["2"]};
              box-shadow: ${shadow};
            `}
          >
            <ArrowDownIcon
              className={css`
                color: black;
              `}
            />
          </VStack>
        </VStack>

        <VStack
          className={css`
            padding: ${theme.spacing["4"]} ${theme.spacing["12"]};
          `}
        >
          <div
            className={css`
              text-align: center;
            `}
          >
            To <NounResolvedLink resolvedName={delegate.address.resolvedName} />{" "}
            who represents
          </div>

          <ENSAmountDisplay fragment={delegate.tokensRepresented.amount} />
        </VStack>
      </VStack>

      {!currentAccount && (
        <ConnectKitButton.Custom>
          {({ isConnected, show, address }) => {
            return (
              <button
                onClick={show}
                className={css`
                  text-align: center;
                  border-radius: ${theme.spacing["2"]};
                  border: 1px solid ${theme.colors.gray.eb};
                  font-weight: ${theme.fontWeight.semibold};
                  padding: ${theme.spacing["4"]} 0;
                  cursor: pointer;
                  :hover {
                    background: ${theme.colors.gray.eb};
                  }
                `}
              >
                {isConnected ? address : "Connect your wallet"}
              </button>
            );
          }}
        </ConnectKitButton.Custom>
      )}
      {isLoading && (
        <DelegateButton disabled={false}>
          Submitting your delegation...
        </DelegateButton>
      )}
      {isSuccess && <Message>Delegation completed!</Message>}
      {isError && (
        <DelegateButton disabled={false}>Delegation failed</DelegateButton>
      )}
      {!isError && !isSuccess && !isLoading && currentAccount && (
        <DelegateButton disabled={false} onClick={() => delegateVotes()}>
          Delegate your votes
        </DelegateButton>
      )}
    </VStack>
  );
}

type DelegateButtonProps = {
  onClick?: () => void;
  disabled: boolean;
  children: ReactNode;
};

const DelegateButton = ({
  children,
  disabled,
  onClick,
}: DelegateButtonProps) => {
  const effectiveOnClick = !disabled ? onClick : undefined;
  return (
    <div
      onClick={effectiveOnClick}
      className={css`
        text-align: center;
        border-radius: ${theme.spacing["2"]};
        border: 1px solid ${theme.colors.gray.eb};
        font-weight: ${theme.fontWeight.semibold};
        padding: ${theme.spacing["4"]} 0;
        cursor: pointer;

        ${!effectiveOnClick &&
        css`
          background: ${theme.colors.gray.eb};
          color: ${theme.colors.gray["700"]};
          cursor: not-allowed;
        `}

        :hover {
          background: ${theme.colors.gray.eb};
        }
      `}
    >
      {children}
    </div>
  );
};

const Message = ({ children }: { children: ReactNode }) => (
  <div
    className={css`
      text-align: center;
      font-weight: ${theme.fontWeight.semibold};
    `}
  >
    {children}
  </div>
);
