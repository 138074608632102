import { Signer, TypedDataDomain, ethers } from "ethers";
import { GnosisSafe, GnosisSafe__factory } from "../contracts/generated";
import { _TypedDataEncoder } from "ethers/lib/utils.js";

type TransactionServiceSafeMessage = {
  messageHash: string;
  status: string;
  logoUri: string | null;
  name: string | null;
  message: string; //| EIP712TypedData,
  creationTimestamp: number;
  modifiedTimestamp: number;
  confirmationsSubmitted: number;
  confirmationsRequired: number;
  proposedBy: { value: string };
  confirmations: [
    {
      owner: { value: string };
      signature: string;
    }
  ];
  preparedSignature: string | null;
};

async function tryFetchMessage(
  safeMessageHash: string
): Promise<TransactionServiceSafeMessage | undefined> {
  const response = await fetch("/fetch_signature", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      safeMessageHash,
    }),
  });

  return (await response.json()) as TransactionServiceSafeMessage | undefined;
}

async function getSafeSignature(safe: GnosisSafe, hashed: string) {
  const messageHash = await safe.getMessageHash(hashed);
  try {
    const safeMessage = await tryFetchMessage(messageHash);
    return safeMessage?.preparedSignature;
  } catch (e) {
    return false;
  }
}

export async function trySignSafeMessage(
  signer: Signer,
  provider: ethers.providers.Provider,
  signerAddress: string,
  signaturePayload: string
): Promise<string | void> {
  const gnosisSafe = GnosisSafe__factory.connect(signerAddress, provider);
  const hashed = ethers.utils.hashMessage(signaturePayload);
  const safeSignature = await getSafeSignature(gnosisSafe, hashed);
  if (safeSignature) {
    // already signed
    return safeSignature;
  }

  await signer.signMessage(signaturePayload);
}

export async function trySignSafeTypedData(
  signer: Signer,
  provider: ethers.providers.Provider,
  signerAddress: string,
  domain: TypedDataDomain,
  types: Record<string, Array<{ name: string; type: string }>>,
  value: Record<string, any>
): Promise<string | void> {
  const gnosisSafe = GnosisSafe__factory.connect(signerAddress, provider);
  const hashed = _TypedDataEncoder.hash(domain, types, value);
  const safeSignature = await getSafeSignature(gnosisSafe, hashed);
  if (safeSignature) {
    // already signed
    return safeSignature;
  }

  await signer.signMessage(hashed);
}
